import React, { useState, useRef, useEffect } from 'react'
import Img from "gatsby-image"
import Formsy from 'formsy-react';
import BackgroundImage from 'gatsby-background-image'
import axios from "axios"

import FormsyInput from "./../Formsy/formsyInput"
import FormsyTextArea from "./../Formsy/FormsyTextArea"

import "./ContactForm.css";


function ContactFormNew(props) {
    const data = props;

    console.log(data)

    const formRef = useRef(null);


    const [canSubmit, setCanSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailFailed, setEmailFailed] = useState(false);
    const [emailResponse, setEmailResponse] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showErrorCheckbox, setShowErrorCheckbox] = useState(false);
    const [newSubHeader, setNewSubHeader] = useState("");
    const [hasGdpr, setHasGdpr] = useState(false)

    useEffect(() => {
        if (canSubmit) {
            setShowErrorMessage(false)
        }
    }, [canSubmit])

    useEffect(() => {
        if (window.location.href.includes("epidoma-paidiou-a21")) {
            setNewSubHeader("Συμπληρώστε την παρακάτω φόρμα για να υποβάλετε αίτηση για το επίδομα παιδιού Α21 και θα επικοινωνήσουμε μαζί σας το συντομότερο δυνατό.")
        } else if (window.location.href.includes("koines-forologikes-diloseis") || window.location.href.includes("atomikes-forologikes-diloseis")) {
            setNewSubHeader("Συμπληρώστε την παρακάτω φόρμα για να υποβάλετε αίτηση για τη φορολογική σας δήλωση και θα επικοινωνήσουμε μαζί σας το συντομότερο δυνατό.")
        }

        if (props.endPoint === "contactEmail") {
            setHasGdpr(true);
        }
    }, [])


    function disableButton() {
        setCanSubmit(false);
    }

    function enableButton() {
        setCanSubmit(true);
    }

    function submit(model) {
        setLoading(true)

        let type = "Αίτηση για Ατομική Φορολογική Δήλωση";

        if (model.fullName) {
            let payload = {
                ...model,
                language: "el",
                type: type,
            }

            console.log(payload);

            axios.post(`https://f2.enimeris.com/wp-json/myplugin/v1/${props.endPoint}`, payload)
                .then(function (response) {
                    // handle success
                    setEmailFailed(false)
                    setLoading(false)
                    setEmailSent(true)
                    setEmailResponse(response.data)

                    if (window.location.href.includes("atomikes-forologikes-diloseis")) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'single_application_submitted'
                        });
                    } else if (window.location.href.includes("koines-forologikes-diloseis")) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'couple_application_submitted'
                        });
                    } else if (window.location.href.includes("epidoma-paidiou-a21")) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'a21_application_submitted'
                        });
                    }

                })
                .catch(function (error) {
                    // handle error
                    setLoading(false)
                    setEmailFailed(true)
                    setEmailResponse(data.responses.failed)
                })
        }
    }

    function test(e) {
        e.preventDefault();
        console.log(e)
        if (!hasGdpr) {
            setShowErrorCheckbox(true)
        } else {
            setShowErrorMessage(true)
        }

    }

    return (
        <div className={`${emailSent ? "email-sent-container" : "cf-container"} py-24`}>
            <div className={`m-auto ${emailSent ? "max-w-none" : "max-w-xl"} px-4 relative`} >
                {loading && (
                    <div className="loader-container">
                        <div className="loader">Loading...</div>
                    </div>
                )}

                {emailFailed && (
                    <p style={{ color: "#b70000", fontSize: "1.2rem" }}>{emailResponse}</p>
                )}

                {!emailSent
                    ?
                    <Formsy
                        onValidSubmit={submit}
                        onValid={enableButton}
                        onInvalid={disableButton}
                        className={loading ? "opacity-25" : "opacity-100"}
                        ref={formRef}
                    >
                        <h2 className="text-4xl text-white text-center">
                            {data.form.title}
                        </h2>
                        <div>
                            <p className="text-md text-white text-center">
                                {newSubHeader !== "" ? newSubHeader : data.form.subTitle}
                            </p>
                        </div>
                        <div className="mt-12">
                            <FormsyInput
                                name="fullName"
                                placeholder={data.form.fullName}
                                classes="mt-4"
                                required
                            />
                            <FormsyInput
                                name="email"
                                validations={"isEmail"}
                                validationErrors={{
                                    isEmail: data.form.email.errorMessage,
                                }}
                                placeholder={data.form.email.label}
                                classes="mt-4"
                                required
                            />
                            <FormsyInput
                                name="telephone"
                                validations={"minLength:10"}
                                type="number"
                                validationErrors={{
                                    minLength: data.form.telephone.errorMessage,
                                }}
                                placeholder={`${data.form.telephone.label} *`}
                                classes="mt-4"
                                required={true}
                            />

                            <FormsyInput
                                name="taxisUser"
                                placeholder={"Taxisnet Username *"}
                                classes="mt-4"
                                required
                            />

                            <FormsyInput
                                name="taxisPass"
                                type="password"
                                placeholder={"Taxisnet Password *"}
                                classes="mt-4"
                                required
                            />

                            {(props.endPoint === "promoEmail") && (
                                <div className="pt-4">
                                    <input
                                        name="aggreement"
                                        type="checkbox"
                                        checked={hasGdpr}
                                        onChange={() => setHasGdpr(!hasGdpr)}
                                    />
                                    <span className="text-white pl-2">Αποδέχομαι
                                        <a href="http://f2.enimeris.com/wp-content/uploads/2020/07/Υπεύθυνη-Δήλωση-Προστασίας-Προσωπικών-Δεδομένων-Φυσικών-Προσώπων-Enimeris-1.pdf" style={{ color: "#79d3ff" }} target="_blank"> τη Δήλωση Προστασίας Προσωπικών Δεδομένων </a></span>
                                </div>
                            )}

                        </div>
                        {(canSubmit && hasGdpr)
                            ?
                            <button className={"bttn-active cf-bttn"}>
                                {data.form.sendButton}
                                <span>{hasGdpr}</span>
                                {console.log((canSubmit && hasGdpr))}
                            </button>
                            :
                            <button className={"cf-bttn"} onClick={(e) => test(e)}>
                                {data.form.sendButton}
                                <span>{hasGdpr}</span>
                                {console.log((canSubmit && hasGdpr))}
                            </button>
                        }
                        {showErrorMessage && (
                            <p className="text-center mt-8" style={{ color: "#b70000", fontSize: "1.2rem", }}>Συμπληρώστε όλα τα απαραίτητα πεδία (*)</p>
                        )}
                        {showErrorCheckbox && (
                            <p className="text-center mt-8" style={{ color: "#b70000", fontSize: "1.2rem", }}>Παρακαλoύμε αποδεχτείτε τους όρους χρήσης</p>
                        )}
                    </Formsy>
                    :
                    <div >
                        <div className="email-response" dangerouslySetInnerHTML={{ __html: emailResponse }} />
                    </div>
                }

            </div>
        </div>
    )
}

export default ContactFormNew;