import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Modal from 'react-modal';

import Img from "gatsby-image"
import logoWhite from "../images/enimeris-logo-website-white.png";
import clearWhite from "../images/times-solid.svg";

import ContactFormNew from "../components/Global/ContactFormNew"
import ContactFormNewEn from "../components/Global/ContactFormNewEn"


import "./../components/PromoTemplate.css"

const customStyles = {
    overlay: {
        zIndex: "3"
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0px',
    }
};


function GeneralTemplate(props) {
    const [modalIsOpen, setIsOpen] = useState(false);

    let data = props.data.wpgraphql;


    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://enimeris.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://enimeris.com${currentPage}`,
            fbType: "website",
            locale: language ? language.toLowerCase() : "",
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            {/* <Layout
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}
            > */}
            <div>
                <div className="promo-intro">
                    <div className="pt-12">
                        <div className="text-white promo-contact-info flex">
                            <a href={`tel:${data.template.contactInformationTemplate.telephoneNumber}`}>
                                {data.template.contactInformationTemplate.telephoneNumber}
                            </a>
                            <div className="px-4">|</div>
                            <a href={`mailto:${data.template.contactInformationTemplate.supportEmail}`}>
                                {data.template.contactInformationTemplate.supportEmail}
                            </a>
                        </div>
                        <Link to={"/"}>
                            <img className="w-56 md:w-64 m-auto" src={logoWhite} alt="white-logo" />
                        </Link>
                    </div>
                    <div className="flex items-center" style={{ flexGrow: "1" }}>
                        <div className="flex container m-auto pb-16 pt-8">
                            <div className="w-full md:w-1/2 p-4 md:pr-8" >
                                <div className="text-4xl text-white text-center md:text-left">
                                    <h1>{data.page.promoPageAcf.headerH1}</h1>
                                </div>
                                <div className="promo-content py-8 text-center md:text-left" dangerouslySetInnerHTML={{ __html: data.page.content }}></div>
                                <div className="flex justify-center md:block ">
                                    {data.page.promoPageAcf.ctaButton === "Form"
                                        ?
                                        <button className="cf-bttn" onClick={openModal}>
                                            {data.page.promoPageAcf.ctaButtonText}
                                        </button>
                                        :
                                        <a>
                                            {data.page.promoPageAcf.ctaUrl.ctaText}
                                        </a>
                                    }
                                </div>
                            </div>
                            <div className="hiddel md:inherit md:w-1/2" >
                                <div>
                                    <Img fluid={data.page.promoPageAcf.backgroundImage.imageFile.childImageSharp.fluid} alt={data.page.promoPageAcf.backgroundImage.altText} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    className={"promo-modal"}
                    contentLabel="Contact Modal"
                >
                    <button onClick={closeModal} className="promo-close-bttn">
                        <img className="w-6" src={clearWhite} alt="white-clear" />
                    </button>

                    {data.page.title == "#Promo"
                        ?
                        <ContactFormNew
                            form={data.template.contactInformationTemplate.form}
                            responses={data.template.contactInformationTemplate.responseMessages}
                            locale={props.pageContext.language ? props.pageContext.language.toLowerCase() : ""}
                            endPoint={"promoEmail"}
                            subject="Φορολογική Δήλωση"
                            teleRequired={false}
                            subjectRequired={data.page.title === "#Promo2" ? false : true}
                            messageRequired={data.page.title === "#Promo2" ? false : true}

                        />
                        :
                        <ContactFormNewEn
                            form={data.template.contactInformationTemplate.form}
                            responses={data.template.contactInformationTemplate.responseMessages}
                            locale={props.pageContext.language ? props.pageContext.language.toLowerCase() : ""}
                            endPoint={"promoEmail"}
                            subject="Φορολογική Δήλωση"
                            teleRequired={false}
                            subjectRequired={data.page.title === "#Promo2" ? false : true}
                            messageRequired={data.page.title === "#Promo2" ? false : true}

                        />

                    }

                </Modal>

            </div>
            {/* </Layout> */}
        </div>
    )
}

export default GeneralTemplate;

export const pageQuery = graphql`
    query GET_PROMO_PAGE($id: ID!, $themeTemplateUri: ID!) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                title
                content
                promoPageAcf{
                    headerH1
                    backgroundImage {
                        altText
                        sourceUrl
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 2080, quality:80){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    ctaButton
                    ctaButtonText
                    ctaUrl {
                        ctaText
                        ctaUrl
                        fieldGroupName
                    }
                    followHeader
                    followSubHeader
                    followList
                    pricingPackets{
                        packet{
                            label
                            value
                            price
                        }
                    }
                }
            }
            template(id: $themeTemplateUri, idType: SLUG) {
                contactInformationTemplate {
                    informationTitle
                    telephoneNumber
                    supportEmail
                    address {
                        addressGoogleLink
                        addressLabel
                    }
                    hoursTitle
                    hoursLabel
                    form {
                        title
                        subTitle
                        fullName
                        email{
                            label
                            errorMessage
                        }
                        subject
                        message{
                            label
                            errorMessage
                        }
                        telephone{
                            label
                            errorMessage
                        }
                        sendButton
                    }
                    responseMessages{
                        success
                        failed
                    }
                }
            }
        }
    }
`
